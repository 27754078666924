<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="space-y-7" style="width: 350px; margin-right: 20px;">
        <form v-on:submit.prevent="importSheetFromPdf" v-if="!urls.length">
          <div class="card p-3 mb-4" v-for="(sheet,index) in sheets" :key="'sheet' + index">
            <div>
              <div class="flex justify-content-between">
                <label class="font-semibold">Bản nhạc số {{ index+1 }}</label>
                <div class="flex">
                  <span class="flex items-center mr-2"><label style="margin-bottom:0" :for="'radioOld' + index">Có sẵn</label><input :id="'radioOld' + index" class="ml-1" type="radio" v-model="sheet.new" v-bind:value="false"/></span>
                  <span class="flex items-center"><label style="margin-bottom:0" :for="'radioNew' + index">Tạo mới</label><input :id="'radioNew' + index" class="ml-1" type="radio" v-model="sheet.new" v-bind:value="true"/></span>
                </div>
              </div>
              
              <multiselect v-if="!sheet.new"
                  v-model="sheets[index]"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchSheets"
              >
                <template v-slot:option="{ option }">
                  {{ option.title }}
                  {{ option.year ? ' (' + option.year + ') ' : '' }}
                  {{ option.composers && option.composers.data ? ' - ' + getPeopleNames(option.composers.data) : ''}}
                </template>

                <template v-slot:singlelabel="{ value }">
                  <div class="multiselect-single-label">
                    {{ value.title }}
                    {{ value.year ? ' (' + value.year + ') ' : '' }}
                    {{ value.composers && value.composers.data ? ' - ' + getPeopleNames(value.composers.data) : ''}}
                  </div>
                </template>
              </multiselect>
            </div>
            
            <div class="mt-3" v-if="sheet.new">
              <label :for="'title' + index" class="font-semibold">Tên bản nhạc</label>
              <input required :id="'title' + index" v-model="sheet.title" placeholder="" class="shadow-none with-border" type="text" autocomplete="off">
            </div>

            <div class="mt-3" v-if="sheet.new">
              <label :for="'year' + index" class="font-semibold">Năm sáng tác</label>
              <input required :id="'year' + index" v-model="sheet.year" placeholder="" class="shadow-none with-border" type="text" autocomplete="off">
            </div>

            <div class="mt-3" v-if="sheet.new">
              <label class="font-semibold">Sáng tác <i class="far fa-question-circle"
                                                     :uk-tooltip="'Chọn tác giả nhạc trong danh sách'"></i></label>
              <multiselect
                  v-model="sheets[index].composers"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchComposers"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3" :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{ option.title }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'" class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                    <span class="multiselect-tag-remove-icon"></span>
                  </span>
                  </div>
                </template>
              </multiselect>
            </div>
            <div class="mt-3">
              <label :for="'pages' + index" class="font-semibold">Trang. Vd: 3,4,5 hoặc 7-9</label>
              <input required :id="'pages' + index" v-model="sheet.pages" placeholder="" class="shadow-none with-border" type="text" autocomplete="off">
            </div>
            <div class="flex justify-content-end mt-2">
              <button type="button" v-on:click="removeSheet(index)"
                class="text-white hover:text-white hover:bg-red-700 bg-red-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-1 text-center inline-flex items-center">
                <i class="uil uil-trash"></i>
              </button>
            </div>
          </div>

          <div class="flex justify-content-end mt-4">
            <button type="button" v-on:click="initSheet"
                class="text-white hover:text-white hover:bg-blue-700 bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">+ Thêm bản nhạc
            </button>
          </div>
        </form>
      </div>
      <div style="position: fixed;left: 370px;top: 0;bottom:0; right: 0;">
          <DropZone :cols="cols" :key="'dropzone' + random" type="pdf" v-model:objectIdProp="pdf_url" class="w-full"/>
          <div class="mt-5 flex justify-content-between">
            <select class="mr-3" v-model="cols">
              <option value="4">4 cột</option>
              <option value="6">6 cột</option>
              <option value="8">8 cột</option>
              <option value="10">10 cột</option>
            </select>
            <router-link class="whitespace-nowrap mr-3 text-white hover:text-white hover:bg-blue-700 bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center" :to="{name: 'Home'}">Trang chủ</router-link>
            <button v-if="pdf_url" uk-toggle="target: #importSheetModal" type="buton"
                class="whitespace-nowrap text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">Bắt đầu xử lý
            </button>
          </div>
      </div>
    </div>

    <div id="importSheetModal" class="create-post is-story" uk-modal>
        <div
            class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

          <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Nhập liệu sheet nhạc từ PDF</h3>
            <button id="importSheetModalClose" class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                    type="button" uk-close
                    uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
          </div>

          <div class="px-5 py-2">
            <div v-if="urls.length" data-simplebar style="height: 250px">
              <h3 class="text-xl font-semibold">Kết quả:</h3>
              <p v-for="(url,index) in urls" :key="'url' + index">
                <a :href="url">{{ url }}</a>
              </p>
            </div>
          </div>

          <div v-if="text" class="flex items-center w-full justify-center border-t p-3">
            <button :disabled="disabled" v-on:click="importSheetFromPdf"
               class="bg-red-600 hover:bg-red-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
               {{ text }}</button>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import {getPeopleNames} from "../../../core/services/utils.service";
import {changePageTitle, canManageSheet, isAdmin} from "../../../core/services/utils.service";
import DropZone from "@/components/DropZone.vue";

export default {
  name: "ImportSheetFromPdf",
  components: {DropZone},
  data() {
    return {
      text: "Tôi tiếp tục",
      pdf_url: "",
      sheets: [],
      urls: [],
      disabled: false,
      session: {
        id: ""
      },
      interval: null,
      cols: 8
    }
  },
  methods: {
    initSheet() {
      let sheet = {
        id: '',
        index: this.sheets.length,
        pages: '',
        title: '',
        year: null,
        composers: [],
        new: false
      };
      this.sheets.push(sheet);
    },
    removeSheet(index) {
      this.sheets = this.sheets.filter((sheet) => {
        return sheet.index !== index
      });
    },
    resetData() {
      this.sheets = [];
      this.text = "Tôi tiếp tục";
      this.pdf_url = "";
      this.session = {
        id: ""
      };
      this.interval = null;
      this.generateRandom();
      this.initSheet();
    },
    importSheetFromPdf() {
      this.urls = [];

      if (!this.sheets.length) {
        this.$toast.error("Bạn phải tạo tối thiểu 1 bản nhạc");
        return;
      }

      for (var i = 0; i < this.sheets.length; i++) {
        if (!this.sheets[i].new && !this.sheets[i].id) {
          this.$toast.error("Bạn chưa chọn bản nhạc số " + (i+1));
          return;
        }

        if (this.sheets[i].new && !this.sheets[i].title) {
          this.$toast.error("Bạn chưa đặt tên cho bản nhạc số " + (i+1));
          return;
        }

        if (this.sheets[i].new && !this.sheets[i].composers.length) {
          this.$toast.error("Bạn chưa chọn sáng tác cho bản nhạc số " + (i+1));
          return;
        }

        if (!this.sheets[i].pages) {
          this.$toast.error("Bạn chưa nhập số trang ở bạn nhạc số " + (i+1));
          return;
        }

        let tmp = this.sheets[i].pages.split(',');
        if (tmp.length) {
          for (var j = 0; j < tmp.length; j++) {
            var page = tmp[j].trim();
            if (parseInt(page) < 1) {
              this.$toast.error("Trang " + page + " ở bạn nhạc số " + (i+1) + " không hợp lệ");
              return;
            }
          }
        }
      }

      this.disabled = true;
      this.text = "Vui lòng chờ...";

      let query = `mutation($sheets: Mixed, $pdf_url: String!) {
        importSheetFromPdf(sheets: $sheets, pdf_url: $pdf_url) {
          id
        }
      }`;

      ApiService.graphql(query, {sheets: this.sheets, pdf_url: this.pdf_url})
        .then(({data}) => {
          if (data.data && data.data.importSheetFromPdf) {
            this.session = data.data.importSheetFromPdf;
            this.initInterval();
          } else if(data.errors) {
            this.$toast.error("Import sheet nhạc không thành công: " + data.errors[0].message);
          }
        })
        .catch((response) => {
          this.$toast.error("Import sheet nhạc không thành công: " + response.message);
          this.disabled = false;
          this.text = "Tôi hiểu";
        });
    },
    async searchSheets(q) {
      let query = `query($q: Mixed, $id: Mixed) {
        sheets(first: 50, orderBy: [{column: "id", order: DESC}], where: {OR: [{column: "title", operator: LIKE, value: $q}, {column: "id", value: $id}]}) {
          data {
            id
            title
            year
            composers(first: 20) {
              data {
                id
                title
                slug
              }
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `%${q}%`, id: q})
          .then(({data}) => {
            if (data.data && data.data.sheets && data.data.sheets.data) {
              for (var i = 0; i < data.data.sheets.data.length; i++) {
                data.data.sheets.data[i].new = false;
              }
              return data.data.sheets.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchComposers(q) {
      let query = `query($q: Mixed) {
        composers(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.composers && data.data.composers.data) {
              data.data.composers.data.map((item) => {
                item.icon = item.avatar ? item.avatar.url : "https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg";
              });
              return data.data.composers.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    generateRandom() {
      this.random = Math.random(0, 10000000) * 1000000;
    },
    getPeopleNames(x) {
      return getPeopleNames(x);
    },
    checkSesisonStatus() {
      let query = `query($id: ID!) {
        importSheetSession(id: $id) {
          id
          status
          result
        }
      }`;

      return ApiService.graphql(query, {id: this.session.id})
          .then(({data}) => {
            if (data.data && data.data.importSheetSession) {
              this.session = data.data.importSheetSession;
              if (this.session.status === "success") {
                this.urls = JSON.parse(this.session.result);
                this.resetData();
                this.initSheet();
                if (this.interval) {
                  clearInterval(this.interval);
                }
                this.$toast.success("Import sheet nhạc thành công");
                this.text = "";
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    initInterval() {
      this.interval = setInterval(() => {
        this.checkSesisonStatus();
      }, 5000);
    }
  },
  mounted() {
    if (!canManageSheet()) {
      this.$router.push({name: "Home"});
      return;
    }
    this.initSheet();
    changePageTitle("Import sheet nhạc từ PDF");
  },
  computed: {
    isAdmin() {
      return isAdmin();
    }
  }
}
</script>
 